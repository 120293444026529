import React, { useEffect } from 'react';
import {
  getTenant,
  getUserName,
  IBLSpinLoader,
} from '@iblai/ibl-web-react-common';
import useFreeTrial from '../../hooks/freetrial/useFreeTrial';
import { useSelector } from 'react-redux';

const AutoCheckOut = () => {
  const { handleSubmitUpgrade } = useFreeTrial(false);
  const auth = useSelector((state) => state.auth);
  const sku = window.location.href.includes('individual')
    ? process.env.REACT_APP_IBL_INDIVIDUAL_PRODUCT_SKU
    : process.env.REACT_APP_IBL_ORGANIZATION_PRODUCT_SKU;
  useEffect(() => {
    localStorage.removeItem('redirect-path');
    handleSubmitUpgrade(
      null,
      sku,
      `/api/service/orgs/${getTenant()}/users/${getUserName()}/stripe/checkout-session/`,
      false
    );
  }, []);

  return (
    <div
      data-testid={'auto-checkout-layout'}
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <IBLSpinLoader size={40} containerHeight={'100%'} />
    </div>
  );
};

export default AutoCheckOut;
